import React from "react";
import { Link } from "gatsby";

//import imgL1Logo from "../../assets/image/png/l1-logo.png";
//import imgL1LogoWhite from "../../assets/image/png/logo-white.png";

const Logo = ({ white, height, className = "", ...rest }) => {
  const color = false === white ? "black" : "white";

  return (
    <Link to="/" className={`${className}`} {...rest}>
      <span
        style={{
          color: color,
          fontWeight: 100,
        }}
      >
        <b>LOANER</b>
        HUB
      </span>
    </Link>
  );
};

/*
      {white ? (
        <img src={imgL1LogoWhite} alt="" />
      ) : (
        <img src={imgL1Logo} alt="" />
      )}
*/

export default Logo;
